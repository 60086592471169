import React from 'react';
import styled from 'styled-components';

type Props = {
  label?: string | React.ReactNode;
  value?: string;
  onChange?: (value: string) => void;
  checked?: boolean;
  className?: string;
};

const Container = styled.button.attrs({
  type: 'button',
})`
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
`;

const Checkbox: React.FC<Props> = ({
  label,
  value,
  onChange,
  checked,
  className,
}) => (
  <Container
    onClick={ () => onChange?.(value) }
    className={ className }
  >
    <input
      type="checkbox"
      value={ value }
      checked={ checked }
      readOnly
      // onChange={ (e) => onChange(e.target.value) }
      style={ { marginRight: 5, position: 'relative', top: 3 } }
    />
    {
      label ? (
        <span>
          { label }
        </span>
      ) : null
    }
  </Container>
);

export default Checkbox;
